<template>
	<div class="questions">
		<div class="insert-title">{{$t('open.t2')}}</div>
		<el-form :model="formData" :rules="rules" ref="fb" :hide-required-asterisk="true" class="qjc-clearfix qjc-fts-14">
			<!-- 一、基本资料 -->
			<div class="basic qjc-fl qjc-wid-100">
				<h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{$t('open.t16')}}</h3>
				<el-form-item
					prop="country_id"
					:label="$t('open.t17')"
					class="area qjc-fl qjc-wid-50"
				>
					<div class="">
						<el-select
							v-model="formData.country_id"
							@change="handleAddress"
						>
							<el-option
								v-for="item in areaList"
								:key="item.id"
								:label="item.english_name"
								:value="item.id"
							></el-option>
						</el-select>
					</div>
				</el-form-item>
				<el-form-item
					:label="$t('open.t18')"
					prop="drink"
					class="qjc-fl qjc-wid-50"
				>
					<el-radio-group
						v-model="formData.qndrink"
						class="qjc-block"
					>
						<el-radio :label="1" border>{{$t('open.q1')}}</el-radio>
						<el-radio :label="2" border>{{$t('open.q4')}}</el-radio>
						<el-radio :label="3" border>{{$t('open.q2')}}</el-radio>
					</el-radio-group>
				</el-form-item>
				
				<el-form-item
					:label="$t('open.t19')"
					prop="smoke"
					class="qjc-fl qjc-wid-50"
				>
					<el-radio-group
						v-model="formData.qnsmoke"
						class="qjc-block"
					>
						<el-radio :label="1" border>{{$t('open.q5')}}</el-radio>
						<el-radio :label="2" border>{{$t('open.q8')}}</el-radio>
						<el-radio :label="3" border>{{$t('open.q6')}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('open.f1')" prop="qnmh" class="qjc-fl qjc-wid-50">
					<el-radio-group v-model="formData.qnmh">
						<el-radio :label="1" border>{{$t('open.f23')}}</el-radio>
						<label>
							<el-radio :label="2" class="qnmh-radio" border>{{$t('open.f24')}}</el-radio>
							<el-input type="number" v-if="formData.qnmh == 2" class="qntimes-input" v-model="formData.qntimes" :placeholder="$t('open.f12')"></el-input>
						</label>
					</el-radio-group>
				</el-form-item>

				<el-form-item :label="$t('open.f2')" prop="qnpbf" class="qjc-fl qjc-wid-50 margin-bottom-2 ">
					<el-radio-group v-model="formData.qnpbf" class="qjc-block">
						<el-radio :label="1" border>{{$t('open.f23')}}</el-radio>
						<el-radio :label="2" border>{{$t('open.f24')}}</el-radio>
						<el-radio :label="3" border>{{$t('open.f7')}}</el-radio>
					</el-radio-group>
				</el-form-item>

				

				<el-form-item :label="$t('open.f3')" prop="selfcare" class="qjc-fl qjc-wid-50">
					<el-radio-group v-model="formData.selfcare" class="qjc-block">
						<el-radio class="selfcare-radio" :label="1" border>{{$t('open.f8')}}</el-radio>
						<el-radio class="selfcare-radio no-margin" :label="2" border>{{$t('open.f9')}}</el-radio>
						<el-radio class="selfcare-radio no-margin" :label="3" border>{{$t('open.f10')}}</el-radio>
					</el-radio-group>
				</el-form-item>
				
				<el-form-item :label="$t('open.f4')" prop="tscore" class="qjc-fl qjc-wid-50">
					<el-input type="number" class="tscore-input" v-model="formData.tscore" :placeholder="$t('open.f13')"></el-input>
				</el-form-item>
			</div>

			<!-- 二、个人病史 -->
			<div class="fb qjc-fl qjc-wid-50">
				<h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{$t('open.t27')}}</h3>

				<el-form-item :label="$t('open.f5')" prop="hasFb">
					<el-radio-group v-model="hasFb" class="qjc-block" @change="hasFb ? '' : (fbCheckList = [])">
						<el-radio :label="false" class="wid-96 hasFb-radio" border>{{$t('open.q24')}}</el-radio>
						<el-radio :label="true" class="wid-96 no-margin" border>{{$t('open.q30')}}</el-radio>
					</el-radio-group>

					<el-checkbox-group v-model="fbCheckList" class="qjc-clearfix" :disabled="!hasFb">
						<el-checkbox label="1" class="qjc-fl">tuberculosis</el-checkbox>
						<el-checkbox label="2" class="qjc-fl">malignant tumor</el-checkbox>
						<el-checkbox label="3" class="qjc-fl">non-malignant tumor</el-checkbox>
						<el-checkbox label="4" class="qjc-fl">thyroid Disease</el-checkbox>
						<el-checkbox label="5" class="qjc-fl">diabetes</el-checkbox>
						<el-checkbox label="6" class="qjc-fl">Nutritional/electrolyte imbalances, anemia disorders</el-checkbox>
						<el-checkbox label="7" class="qjc-fl">High blood lipids/high cholesterol</el-checkbox>
						<el-checkbox label="8" class="qjc-fl">gout</el-checkbox>
						<el-checkbox label="9" class="qjc-fl">Blood and Hematopoietic Related Diseases</el-checkbox>
						<el-checkbox label="10" class="qjc-fl">Cognitive impairment/dementia (including Parkinson's disease)</el-checkbox>
						<el-checkbox label="11" class="qjc-fl">Mental disorders</el-checkbox>
						<el-checkbox label="12" class="qjc-fl">Non-psychotic mental disorders</el-checkbox>
						<el-checkbox label="13" class="qjc-fl">Central Nervous System Diseases</el-checkbox>
						<el-checkbox label="14" class="qjc-fl">Diseases of the terminal nervous system</el-checkbox>
						<el-checkbox label="15" class="qjc-fl">Visual impairment</el-checkbox>
						<el-checkbox label="16" class="qjc-fl">Dizziness and vestibular disorders</el-checkbox>
						<el-checkbox label="17" class="qjc-fl">Hearing impairment</el-checkbox>
						<el-checkbox label="18" class="qjc-fl">hypertension</el-checkbox>
						<el-checkbox label="19" class="qjc-fl">heart disease</el-checkbox>
						<el-checkbox label="20" class="qjc-fl">Cerebrovascular disease</el-checkbox>
						<el-checkbox label="21" class="qjc-fl">Arterial and capillary diseases</el-checkbox>
						<el-checkbox label="22" class="qjc-fl">Diseases of the veins and lymphatic vessels</el-checkbox>
						<el-checkbox label="23" class="qjc-fl">Chronic Obstructive Pulmonary Disease</el-checkbox>
						<el-checkbox label="24" class="qjc-fl">Gastrointestinal diseases</el-checkbox>
						<el-checkbox label="25" class="qjc-fl">Chronic liver disease</el-checkbox>
						<el-checkbox label="26" class="qjc-fl">Nephrotic syndrome</el-checkbox>
						<el-checkbox label="27" class="qjc-fl">Diseases of the prostate gland</el-checkbox>
						<el-checkbox label="28" class="qjc-fl">Diseases of the female reproductive system</el-checkbox>
						<el-checkbox label="29" class="qjc-fl">Joint diseases</el-checkbox>
						<el-checkbox label="30" class="qjc-fl">Diseases of the cervical spine and back</el-checkbox>
						<el-checkbox label="31" class="qjc-fl">Rheumatism</el-checkbox>
						<el-checkbox label="32" class="qjc-fl">Musculoskeletal disorders</el-checkbox>
						<el-checkbox label="33" class="qjc-fl">Sleep disorders</el-checkbox>
						<el-checkbox label="34" class="qjc-fl">Chronic pain</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</div>

			<!-- 三、个人手术史 -->
			<div class="service qjc-fl qjc-wid-50">
				<h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{$t('open.t28')}}</h3>

				<el-form-item :label="$t('open.f6')" prop="hasService">
					<el-radio-group v-model="hasService" class="qjc-block" @change="hasService ? '' : (serviceCheckList = [])">
						<el-radio :label="false" class="wid-96 hasFb-radio" border>{{$t('open.q24')}}</el-radio>
						<el-radio :label="true" class="wid-96 no-margin" border>{{$t('open.q30')}}</el-radio>
					</el-radio-group>

					<el-checkbox-group v-model="serviceCheckList" class="qjc-clearfix" :disabled="!hasService">
						<el-checkbox label="1" class="qjc-fl">Accompanying for medical treatment</el-checkbox>
						<el-checkbox label="2" class="qjc-fl">Professional Rehabilitation Services</el-checkbox>
						<el-checkbox label="3" class="qjc-fl">Medical consulting services</el-checkbox>
						<el-checkbox label="4" class="qjc-fl">Health management and promotion advisory services</el-checkbox>
						<el-checkbox label="5" class="qjc-fl">Nutrition or health food counseling services</el-checkbox>
						<el-checkbox label="6" class="qjc-fl">Medication Counseling Service</el-checkbox>
						<el-checkbox label="7" class="qjc-fl">Health Screening Counseling Service</el-checkbox>
						<el-checkbox label="8" class="qjc-fl">Accompanying or assisting with social activities</el-checkbox>
						<el-checkbox label="9" class="qjc-fl">Accompanying or assisting with exercise</el-checkbox>
						<el-checkbox label="10" class="qjc-fl">respite service</el-checkbox>
						<el-checkbox label="11" class="qjc-fl">Home cleaning and sanitation services</el-checkbox>
						<el-checkbox label="12" class="qjc-fl">laundry service</el-checkbox>
						<el-checkbox label="13" class="qjc-fl">Home security design consulting services</el-checkbox>
						<el-checkbox label="14" class="qjc-fl">meal service</el-checkbox>
						<el-checkbox label="15" class="qjc-fl">Visiting and accompaniment services</el-checkbox>
						<el-checkbox label="16" class="qjc-fl">Home care manpower provision</el-checkbox>
						<el-checkbox label="17" class="qjc-fl">Insurance Financial Advisory Service</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</div>

			<!-- <el-form-item class="qjc-fl qjc-wid-100 qjc-texta-c"><el-button @click="next" class="next qjc-fts-16 qjc-ftw-500">下一步</el-button></el-form-item> -->
		</el-form>
		
		<div class="footer-btn">
			<el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">{{$t('open.t12')}}</el-button>
			<el-button @click="next" class="next qjc-fts-16 qjc-block">{{$t('open.t13')}}</el-button>
		</div>
	</div>
</template>

<script>
import { getCountryList } from '@/utils/axios'
export default {
	name: 'FbQuestionss',
	data() {
		//验证
		// 是否患有一下疾病
		var fbdiseaseVerify = (rule, value, callback) => {
			if (!this.hasFb) {
				callback(new Error(this.$t('open.f14')));
			} else if (this.hasFb && this.fbCheckList.length > 0) {
				callback();
			}
		};
		return {
			rules: {
				area: [{ required: false, message: this.$t('open.f15') }],
				country_id: [
					{ required: true, message: this.$t('open.f15') },
				],
				qndrink: [{ required: true, message: this.$t('open.f16') }],
				qnsmoke: [{ required: true, message: this.$t('open.f17') }],
				qnmh: [{ required: true, message: this.$t('open.f18') }],
				qnpbf: [{ required: true, message: this.$t('open.f19') }],
				selfcare: [{ required: true, message: this.$t('open.f20') }],
				tscore: [{ required: false, message: this.$t('open.f21') }],
				qntimes: [{ required: false, message: this.$t('open.f22') }],
				fbdisease: [{ validator: fbdiseaseVerify, trigger: 'blur' }],
			},

			// 客户居住地
			areaList: [],
			fbdiseaseList: [
				{"fdis": "F00", "level": "1"},
				{"fdis": "F01", "level": "0"},
				{"fdis": "F02", "level": "0"},
				{"fdis": "F03", "level": "0"},
				{"fdis": "F04", "level": "0"},
				{"fdis": "F05", "level": "0"},
				{"fdis": "F06", "level": "0"},
				{"fdis": "F07", "level": "0"},
				{"fdis": "F08", "level": "0"},
				{"fdis": "F09", "level": "0"},
				{"fdis": "F10", "level": "0"},
				{"fdis": "F11", "level": "0"},
				{"fdis": "F12", "level": "0"},
				{"fdis": "F13", "level": "0"},
				{"fdis": "F14", "level": "0"},
				{"fdis": "F15", "level": "0"},
				{"fdis": "F16", "level": "0"},
				{"fdis": "F17", "level": "0"},
				{"fdis": "F18", "level": "0"},
				{"fdis": "F19", "level": "0"},
				{"fdis": "F20", "level": "0"},
				{"fdis": "F21", "level": "0"},
				{"fdis": "F22", "level": "0"},
				{"fdis": "F23", "level": "0"},
				{"fdis": "F24", "level": "0"},
				{"fdis": "F25", "level": "0"},
				{"fdis": "F26", "level": "0"},
				{"fdis": "F27", "level": "0"},
				{"fdis": "F28", "level": "0"},
				{"fdis": "F29", "level": "0"},
				{"fdis": "F30", "level": "0"},
				{"fdis": "F31", "level": "0"},
				{"fdis": "F32", "level": "0"},
				{"fdis": "F33", "level": "0"},
				{"fdis": "F34", "level": "0"}
			],
			serviceList: [
				{"svc": "V01", "need": "1"},
				{"svc": "V02", "need": "1"},
				{"svc": "V03", "need": "1"},
				{"svc": "V04", "need": "1"},
				{"svc": "V05", "need": "1"},
				{"svc": "V06", "need": "1"},
				{"svc": "V07", "need": "1"},
				{"svc": "V08", "need": "1"},
				{"svc": "V09", "need": "1"},
				{"svc": "V10", "need": "1"},
				{"svc": "V11", "need": "1"},
				{"svc": "V12", "need": "1"},
				{"svc": "V13", "need": "1"},
				{"svc": "V14", "need": "1"},
				{"svc": "V15", "need": "1"},
				{"svc": "V16", "need": "1"},
				{"svc": "V17", "need": "1"},
			],
			hasDisease: '', //是否患有指定疾病
			hasFb: false,
			hasService: false,
			fbCheckList: [],
			serviceCheckList: [],
		};
	},
	props: ['type', 'formData'],
	mounted() {
		//数据修改时  根据已有数据显示内容
		// if (this.type == 'update') {
			if(this.formData.fbdisease) {
				if(this.formData.fbdisease[0].level == 1) {
					this.hasFb = false;
				} else {
					var fbdis = this.formData.fbdisease.filter((d, index) => (index != 0 && d.level != 0));
					this.hasFb = true;
					fbdis.map((item, index) => {
						this.fbdiseaseList.map((fb, fbIndex) => {
							if(item.fdis == fb.fdis) {
								this.fbCheckList.push(fbIndex + '')
							}
						})
					})
				}
				
				var servicedis = this.formData.service.filter(d => d.need != 1);
				if (servicedis && servicedis.length > 0) {
					this.hasService = true;
					servicedis.map((item, index) => {
						this.serviceList.map((ser, serIndex) => {
							if(item.svc == ser.svc) {
								this.serviceCheckList.push(serIndex + 1 + '')
							}
						})
					})
				} else {
					this.hasService = false;
				}
			}
		// }
		this.handleAddressList()
	},
	methods: {
		handleAddress(val) {
			this.formData.country_id = val
		},
		handleAddressList() {
			getCountryList().then((res) => {
				if(res.code == 200) {
					let list = JSON.parse(window.atob(res.data))
					this.areaList = list
				}
			})
		},
		// 下一步
		next() {
			let list = [...this.fbdiseaseList]
			let serlist = [...this.serviceList]
			if(this.hasFb && this.fbCheckList.length <= 0) {
				this.$myAlert({
					type: 'fail',
					content: "Please select the client's medical condition"
				});
				return
			}
			if(this.hasFb && this.fbCheckList.length > 0) {
				this.fbCheckList.map((item, index) => {
					this.fbdiseaseList.map((fb, fbIndex) => {
						if(item == fbIndex) {
							fb.level = 1
						}
					})
				})
				this.formData.fbdisease = list
				this.formData.fbdisease[0].level = 0
			} else {
				this.formData.fbdisease = list
			}
			
			if(this.hasService && this.serviceCheckList.length <= 0){
				this.$myAlert({
					type: 'fail',
					content: 'Please select whether the client requires medical care'
				});
				return
			}
			if(this.hasService && this.serviceCheckList.length > 0) {
				this.serviceCheckList.map((item, index) => {
					serlist.map((ser, serIndex) => {
						if(item == serIndex + 1) {
							ser.need = '2'
						}
					})
				})
				this.formData.service = serlist
			} else {
				this.formData.service = serlist
			}
			if(this.formData.qnmh == '2'){
				if(this.formData.qntimes <= 0){
					// Toast('请填写客户曾经骨折次数')
					this.$myAlert({
						type: 'fail',
						content: 'Please fill in the number of times the customer has broken a bone'
					});
					return
				}
			}
			this.$refs.fb.validate((isSuc, err) => {
				if (isSuc) {
					this.$emit('checkTab', 'FbBodily');
				}
			});
		},
		
		handlePrev() {
			this.$emit('checkTab', 'Personal');
		},
	}
};
</script>

<style scoped>
.el-form >>> .el-form-item__label {
	width: 100%;
	text-align: left;
	color: #40444d;
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 16px;
}
.area >>> .el-form-item__label {
	/* width: 111px; */
}
.el-form >>> .el-form-item__content {
	line-height: 1;
}
.area >>> input {
	width: 500px;
	height: 32px;
	font-size: 14px;
	line-height: 16px;
	padding: 9px 12px;
}
.area >>> .el-input .el-select__caret {
	font-size: 14px;
	line-height: 32px;
}
.el-form >>> .el-radio__label {
	font-size: 14px;
	color: #777f8f;
}
.el-form >>> .diseaseTd {
	color: #777f8f;
	background-color: #f2f4fd;
	padding: 10px 16px;
	line-height: 14px;
	border: 0.5px solid #fff;
}
.el-table >>> .el-checkbox__inner {
	border-color: #777f8f;
}
.el-table >>> .cell {
	padding: 0;
	line-height: 14px;
}
.el-table >>> .el-table__body,
.el-table >>> .el-table__footer,
.el-table >>> .el-table__header {
	table-layout: auto;
}
.el-table >>> .name {
	width: 153px;
}
.el-table >>> .no {
	width: 105px;
}
.el-table >>> .acc {
	width: 120px;
}
.el-table >>> .often {
	width: 136px;
}
.oper >>> .el-checkbox__label,
.famdis >>> .el-checkbox__label {
	font-size: 14px;
}
.oper >>> .el-checkbox__inner,
.famdis >>> .el-checkbox__inner {
	border-color: #777f8f;
}
.questions >>> .el-checkbox__input.is-disabled + span.el-checkbox__label {
	color: #777f8f;
	font-weight: normal;
	font-size: 14px;
}

.margin-bottom-2 {
	height: 80px;
}
.questions >>> .el-radio.is-bordered {
	height: 34px;
	line-height: 32px;
	padding: 0 12px;
	font-size: 14px;
	color: #525866;
	border: 1px solid #CED4F0;
}
.questions >>> .el-input__inner {
	height: 34px;
	line-height: 34px;
}
.questions >>> .el-form .el-form-item .el-radio {
	min-width: 130px;
}

.questions >>> .el-checkbox, .el-checkbox__input {
	white-space: pre-wrap !important
}
</style>
<style lang="scss" scoped>
.questions {
	padding-top: 50px;
	
	.insert-title {
		position: relative;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #293033;
		line-height: 16px;
		padding-bottom: 15px;
		border-bottom: 1px solid #E5E8F5;
		margin-bottom: 32px;
		
		&::before{
			position: absolute;
			left: -24px;
			top: 0;
			content: '';
			width: 4px;
			height: 20px;
			background-color: #6883FB;
		}
	}

	.el-form {
		h3 {
			line-height: 16px;
			margin-bottom: 24px;
		}
		.basic {
			margin-bottom: 40px;

			.el-form-item {
				width: 50%;
			}
		}
		.el-form-item {
			margin-bottom: 25px;

			.area-label {
				line-height: 32px;
			}
			.el-radio {
				margin-bottom: 15px;
				margin-right: 0;
			}

			&.sleep {
				margin-top: -29px;
			}
			.el-table {
				width: 96%;
				margin-left: 24px;
			}

			.next {
				width: 200px;
				height: 48px;
				margin-top: 47px;
			}

			.tscore-input {
				width: 300px;
			}

			.qntimes-input {
				width: 200px;
				
			}
		}
		.el-radio {
			font-weight: normal;
		}
		.oper,
		.fb,
		.service,
		.famdis {
			.el-checkbox-group {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				width: 96%;
				background-color: #f2f4fd;
				// margin-left: 0.24px;

				.el-checkbox {
					display: flex;
					align-items: center;
					
					width: 33.33%;
					line-height: 14px;
					margin-right: 0;
					font-size: 400;
					padding: 10px 16px;
					border: 0.5px solid #fff;
					color: #777f8f;
				}
			}
		}
		.oper {
			margin-bottom: 88px;
		}
	}
	.no-margin {
		margin-left: 0 !important;
	}
	
	.qnmh-radio {
		margin-left: 15px;
	}
	
	.wid-96 {
		width: 96%;
	}
	
	.hasFb-radio {
		width: 96%;
		padding-right: 20px;
	}
	
	.footer-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
	}
			
	.next{
		// width: 96px;
		padding: 0 10px;
		height: 34px;
		border-radius: 17px;
		border: none;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #fff;
	}
	.prev {
		// width: 96px;
		padding: 0 10px;
		height: 34px;
		border-radius: 17px;
		border: 1px solid #6883FB;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #6883FB;
		background-color: #fff;
		margin-right: 24px;
	}
}

.selfcare-radio {
	min-width: 273px !important;
	margin-right: 10px !important;
}
</style>
